import EXIF from 'exif-js';
import {
    orderRefund
} from '@/utils/order.js';
export default {
    name: "ApplyAfter",
    data() {
        return {
            isShow: false,
            column1: ["商品成分与描述不符", "质量问题", "买家发错货", "拍错货"],
            column2: ["七天无理由", "质量问题", "包装/商品破损/污渍", "少件/漏件", "商品与描述不符"],
            selValue: "",
            type: this.$route.query.type,
            isGoods: false,
            goodIndex: -1,
            goodText: "",
            goodsSuccess: false,
            goodsInfo: [],
            fileList: [],
            orderInfo: '',
            userInfo: '',
            refundDetail: ''
        }
    },
    created() {
        this.orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));
        this.goodsInfo = JSON.parse(sessionStorage.getItem('orderInfo')).orderGoodItemList;
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    },
    methods: {
        submit() {
            let aReason = this.type == 3 ? this.goodText : this.selValue;
            if (!aReason) {
                let msg = this.type == 3 ? '换货原因' : '退款原因';
                this.$toast(msg)
                return false;
            }
            this.orderRefundFunc();
            if (this.type == 1) {
                // this.goodsSuccess = true

            } else {
                // this.$router.push({
                //     path: "/afterSuccess"
                // })
            }
        },
        // 退款退货
        orderRefundFunc() {
            let goods = [];
            let aLength = 0;
            this.goodsInfo.forEach(item => {
                let aStr = item.goodId + ',' + item.goodNum + ';';
                goods.push(aStr);
                aLength += item.goodNum;
            })
            console.log('goods', goods.join(';'))
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderId: this.orderInfo.id,
                isReceivedGood: this.type, //(1=已收到货退款，2=未收到货,3=换货)
                refundReason: this.type == 3 ? this.goodText : this.selValue,
                img1: this.fileList[0] ? this.fileList[0] : '',
                img2: this.fileList[1] ? this.fileList[1] : '',
                img3: this.fileList[2] ? this.fileList[2] : '', // (base64格式)
                refundGood: goods.join(''), // (退款商品的itemid,格式为id,num;id,num;)
                refundNum: aLength,
                refundDetail: this.refundDetail
            }
            orderRefund(data).then(res => {
                console.log('退货', res)
                if (res) {
                    this.$router.push({
                        path: '/afterSuccess'
                    })
                }
            })
        },
        haveKnow() {
            this.goodsSuccess = false
            this.$router.go(-1)
        },
        onCancel() {
            this.isShow = false
            this.selValue = ""
        },
        onConfirm(value) {
            this.selValue = value
            this.isShow = false
        },
        goodCancel() {
            this.goodIndex = -1
            this.isGoods = false
            this.goodText = ""
        },
        reasonClick(index) {
            this.goodIndex = index
            this.isGoods = false
            this.goodText = this.column1[index]
        },
        selFun(val) {
            if (val == 3) {
                this.isGoods = true
            } else {
                this.isShow = true
            }
        },
        // 判断上传的图片是否超出10M
        oversizeFunc(e) {
            let aFile = e.target.files[0];
            let _that = this;
            let reader = new FileReader();
            reader.readAsDataURL(aFile);
            reader.onloadstart = function() {
                console.info('开始读取')
            }
            reader.onprogress = function() {
                console.info('正在读取.....')
            }
            reader.onload = function(e) {
                console.log('e', e)
                if (aFile.size > 10 * 1024 * 1024) {
                    _that.$toast('上传的图片大小超过10MB，无法上传');
                    return false;
                }
                _that.uploadImg(this.result, aFile);
            }
            reader.onabort = function() {
                console.info('读取中断！！')
            }
            reader.onerror = function() {
                console.info('读取出现错误！！')
            }
            reader.onloadend = function() {
                console.info('FileReader读取步骤执行完毕')
            }
        },
        // 压缩图片
        uploadImg(e, filedata) {
            // let aFile = e;
            let _that = this;
            let base64Url = e;
            let maxWidth = 800,
                maxHeight = 800;
            let img = new Image();
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');

            // base64地址图片加载完毕后
            img.onload = function() {
                // 图片原始尺寸
                let originWidth = this.width;
                let originHeight = this.height;
                console.log('originWidth', originWidth);
                // 目标尺寸
                let targetWidth = originWidth,
                    targetHeight = originHeight;

                if (originWidth > maxWidth || originHeight > maxHeight) {
                    if (originWidth / originHeight > maxWidth / maxHeight) {
                        // 更宽，按照宽度限定尺寸
                        targetWidth = maxWidth;
                        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                    } else {
                        targetHeight = maxHeight;
                        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                    }
                }

                EXIF.getData(filedata, function() {
                    EXIF.getAllTags(this);
                    let orient = EXIF.getTag(this, 'Orientation'); //判断需不需要旋转的值了，有1、3、6、8
                    switch (orient) {
                        case 6: //需要顺时针90度旋转
                            canvas.width = targetHeight;
                            canvas.height = targetWidth;
                            context.rotate(90 * Math.PI / 180);
                            context.fillStyle = "#FFF";
                            context.fillRect(0, 0, targetWidth, targetHeight);
                            context.drawImage(img, 0, -targetHeight, targetWidth, targetHeight);
                            break;
                        case 8: //需要逆时针90度旋转
                            canvas.width = targetWidth;
                            canvas.height = targetHeight;
                            context.rotate(-90 * Math.PI / 180);
                            context.fillStyle = "#FFF";
                            context.fillRect(0, 0, targetWidth, targetHeight);
                            context.drawImage(img, -targetHeight, 0, targetHeight, targetWidth);
                            break;
                        case 3: //需要180度旋转
                            context.rotate(180 * Math.PI / 180);
                            context.fillStyle = "#FFF";
                            context.fillRect(0, 0, targetWidth, targetHeight);
                            context.drawImage(img, -originWidth, -originHeight);
                            break;
                        default:
                            canvas.width = targetWidth;
                            canvas.height = targetHeight;
                            context.fillStyle = "#FFF";
                            context.fillRect(0, 0, targetWidth, targetHeight);
                            context.drawImage(img, 0, 0, targetWidth, targetHeight);
                    }
                    let base = canvas.toDataURL("image/jpeg", 0.7); //canvas转码为base64
                    base64Url = base;
                    _that.fileList.push(base64Url)
                });
            };

            img.src = base64Url;
        },
        // 删除图片
        deletePicture(index) {
            let arr = this.fileList;
            arr.splice(index, 1);
            this.fileList = arr;
        },
        // 计算订单金额
        orderPriceFunc(goodsAmount, couponCover, bonusCover, freightCharge) {
            let aPrice = Number(goodsAmount - couponCover - bonusCover + freightCharge);
            console.log('aPrice', aPrice);
            return aPrice > 0 ? parseFloat(aPrice / 100).toFixed(2) : '0.00';
        },
    }
}