<template>
  <div class="apply-after">
    <div class="goods-box flex" v-for="item in goodsInfo" :key="item.id">
      <div class="goods-box-img" :style="{background: 'url('+item.goodsImage+')no-repeat center center',backgroundSize: 'cover'}"></div>
      <div class="right">
          <p class="title">{{item.goodName}}</p>
          <p class="attr">规格</p>
          <div class="goot flex align-center flex-between">
              <p>¥{{parseFloat(item.promotionPrice/100).toFixed(2)}}</p>
              <p>×{{item.goodNum}}</p>
          </div>
      </div>
    </div>
    <div v-if="type == 3" class="center-box main-box">
      <div class="t-b flex" @click="selFun(type)">
        <p><span style="color: #e62129">*</span>换货原因</p>
        <p :class="{ 'have-sel': goodText }">
          {{ goodText ? goodText : "请选择" }}
        </p>
        <img src="@/assets/img/order/after/right2.png" alt="" />
      </div>
      <p class="explain">换货说明</p>
      <textarea placeholder="请填写换货说明"></textarea>
    </div>
    <div v-if="type != 3" class="main-box">
      <div class="t-b flex" @click="selFun(type)">
        <p>退款原因</p>
        <p :class="{ 'have-sel': selValue }">
          {{ selValue ? selValue : "请选择" }}
        </p>
        <img src="@/assets/img/order/after/right2.png" alt="" />
      </div>
      <div class="b-b flex">
        <p>退款金额</p>
        <p>¥ {{orderPriceFunc(orderInfo.goodAmount, orderInfo.couponCover, orderInfo.bonusCover, orderInfo.freightCharge)}}</p>
      </div>
    </div>
    <div v-if="type != 3" class="center-box">
      <div class="m-t flex">
        <p>联系方式</p>
        <p>{{userInfo.user_tele}}</p>
      </div>
      <p class="explain">退款说明</p>
      <textarea v-model="refundDetail" placeholder="请填写退款说明"></textarea>
    </div>
    <div class="bottom-box">
      <div class="t-b flex">
        <p>上传凭证</p>
        <p>（最多3张）</p>
      </div>
      <div class="upload-box flex">
        <div class="i-box" v-for="(item,index) in fileList" :key="item.id" :style="{background: 'url('+item+')no-repeat center center',backgroundSize: 'cover'}">
          <img src="@/assets/img/order/after/close.png" alt="" class="close" @click="deletePicture(index)"/>
        </div>
        <div class="upload" v-if="fileList.length < 3">
          <input type="file" accept="image/*" class="upload-input"
          size="10485760" @change="oversizeFunc($event)">
          <img src="@/assets/img/order/after/upload.png" alt="" class="" />
          <p>添加图片</p>
        </div>
      </div>
    </div>
    <div class="sub-box flex flex-center align-center" @click="submit">
      <p>提交</p>
    </div>
    <van-popup
      v-model:show="isShow"
      position="bottom"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="sel-popup"
    >
      <van-picker
        title="退款原因"
        confirm-button-text="确定"
        show-toolbar
        :columns="column2"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup
      v-model:show="isGoods"
      position="bottom"
      :style="{ width: '100%' }"
      class="goods-popup"
    >
      <p class="title">换货原因</p>
      <div class="goods-hold">
        <div
          v-for="(item, index) in column1"
          :key="index"
          :class="['good-li flex', { 'good-red': index == goodIndex }]"
          @click="reasonClick(index)"
        >
          <p>{{ item }}</p>
          <img src="@/assets/img/order/after/selected.png" alt="" />
        </div>
        <p class="b-fix" @click="goodCancel">取消</p>
      </div>
    </van-popup>
    <van-popup
      v-model:show="goodsSuccess"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="goods-success-popup"
    >
      <div class="box-reset">
        <img src="@/assets/img/order/after/sul.png" alt="" />
        <p class="box-reset-text">换货申请已提交</p>
        <p class="box-reset-tip">灵龙客服会在1个工作日内<br />审核售后申请<br />您可以在订单查看售后进度</p>
        <p class="box-reset-bottom" @click="haveKnow">我已知晓</p>
      </div>
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.apply-after {
  .sel-popup {
    .van-picker__cancel {
      height: 22px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500 !important;
      color: #e62129 !important;
      line-height: 22px !important;
    }
    .van-picker__title {
      height: 22px !important;
      font-size: 14px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400 !important;
      color: #666666 !important;
      line-height: 22px !important;
    }
    .van-picker__confirm {
      height: 22px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500 !important;
      color: #e62129 !important;
      line-height: 22px !important;
    }
  }
  .goods-popup {
    width: 100%;
    height: 328px;
    background: #ffffff;
    -webkit-border-radius: 10px 10px 0px 0px;
    border-radius: 10px 10px 0px 0px;
    .title {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a4a4a;
      line-height: 22px;
      text-align: center;
      padding: 15px 0 16px 0;
      border-bottom: solid 1px #eeeeee;
    }
    .goods-hold {
      width: 345px;
      padding: 0 5px 0 0;
      margin: 0 auto;
      .good-li {
        height: 20px;
        padding: 15px 0;
        border-bottom: solid 1px #eeeeee;
        p {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          flex-grow: 1;
        }
        img {
          width: 14px;
          height: 14px;
          flex-shrink: 0;
          margin-top: 2px;
          opacity: 0;
        }
      }
      .good-red {
        p {
          color: #e62129;
        }
        img {
          opacity: 1;
        }
      }
      .b-fix {
        position: fixed;
        width: 314px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #f6f6f6;
        border-radius: 22px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: 15px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .goods-success-popup {
    width: 280px !important;
    top: 178px !important;
    transform: translateX(-50%) !important;
    background: none !important;
    overflow: unset !important;
    .box-reset {
      position: relative;
      width: 280px;
      height: 169px;
      background: #ffffff;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      padding-top: 33px;
      position: relative;
      img {
        width: 64px;
        height: 64px;
        position: absolute;
        left: 108px;
        top: -41px;
      }
      &-text {
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        text-align: center;
      }
      &-tip {
        height: 44px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        text-align: center;
        margin: 20px 0 0;
      }
      &-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #e62129;
        border-top: solid 1px #f0f0f0;
      }
    }
  }
}
</style>